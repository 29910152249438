.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.foo-open {
  background-color: #29323b;
  padding: 17px;
  color: #7a7a7a;
  height: auto;
  -webkit-transition: height 1s linear;
  -moz-transition: height 1s linear;
  -o-transition: height 1s linear;
  transition: height 1s linear;
}

.foo-closed {
  /* css animation */
  overflow: hidden;
  height: 0;
  -webkit-transition: height 1s linear;
  -moz-transition: height 1s linear;
  -o-transition: height 1s linear;
  transition: height 1s linear;
}
.categoryContainer {
  -webkit-transition: height 1s linear;
  -moz-transition: height 1s linear;
  -o-transition: height 1s linear;
  transition: height 1s linear;
}
